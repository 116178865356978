.droneDiagram ol.diagramList {
  // @include breakpoint(medium) {
  //   margin-top:25%;
  // }
  li {
    padding:8px;
  }
}

.droneDiagramImg {
  @include breakpoint(medium) {
    margin-top:10%;
  }
}