#nav-bar-outer,
.top-bar,
.top-bar .logo,
.top-bar .menu {
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    z-index: 999998;
}

.logo {
    display:inline-block;
}

.nav-up {
    //top: -73px;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
}

.top-bar .menu a {
    color:black;
}

@include breakpoint(small) {
    .orbit-previous,
    .orbit-next {
        top: 25%;
    }
}

@include breakpoint(medium) {
    .orbit-previous,
    .orbit-next {
        top: 40%;
    }
}

@include breakpoint(medium down) {
    $overlay-nav-menu-toggle-size: rem-calc(26px);
    $overlay-nav-menu-toggle-color: $black;
    $overlay-nav-menu-link-height: rem-calc(22px);
    $overlay-nav-menu-link-color: $white;
    $overlay-nav-menu-link-border: 1px solid rgba($overlay-nav-menu-link-color, 0.5);
    $overlay-nav-menu-bg: $black;
    .relative {
        position: inherit;
    }
    .top-bar {
        background: $black;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        .logo {
            background: url("../img/colorLogoSmall.png") no-repeat;
            height: 53px;
            width: 94px;
            display: inline-block;
            z-index: 999999;
        }
        .menu {
            line-height: 73px;
            li a {
                color: white;
            }
        }
        .button.hollow {
            border: 2px solid $white;
            color: $white !important;
            background: none;
        }
    }

    // .top-bar {
    //     background: $white;
    //     border-bottom: #17181a;
    //     box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    //     .logo {
    //         background: url("../img/colorLogoSmall.png") no-repeat;
    //         height: 53px;
    //         width: 94px;
    //         display: inline-block;
    //         z-index: 999999;
    //     }
    //     .menu {
    //         line-height: 73px;
    //     }
    //     .button.hollow {
    //         border: 2px solid $white;
    //         color: $white !important;
    //         background: none;
    //     }
    //     .relative {
    //         position: inherit;
    //     }
    // }

    .top-bar ul {
        background: inherit;
    }
    .overlay-nav-menu-toggle {
        position: absolute;
        font-size: 1.625rem;
        z-index: 5000;
        color: $white;
        transition: all 0.4s ease-in-out;
        right: 15px;
        top: 20px;
        z-index: 999999;
        &:hover,
        &:focus,
        &:visited,
        &:active {
            transition: all 0.4s ease-in-out;
            color: $white;
        }
    }
    ul.overlay-nav-menu {
        position: absolute;
        z-index: 1000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        margin: 0;
        justify-content: center;
        opacity: 0;
        list-style: none;
        background: rgba($overlay-nav-menu-bg, 0.8);
        background-size: cover;
        text-align: center;
        transform: translateX(-100%) rotateX(40deg);
        transition: all 400ms cubic-bezier(1.000, -0.400, 0.440, 0.985);
        li {
            font-size: rem-calc(18px);
            display: block !important;
        }
        a {
            display: block;
            color: $overlay-nav-menu-link-color;
            font-weight: bold;
            // height: $overlay-nav-menu-link-height;
            line-height: $overlay-nav-menu-link-height;
            border-bottom: $overlay-nav-menu-link-border;
            text-decoration: none;
            padding: rem-calc(10px);
            &:hover {
                background-color: rgba($white, 0.6);
                color: $black;
            }
        }
        &.is-open {
            top: 0;
            opacity: 1;
            transform: perspective(200px) translateX(0) rotateX(0);
            padding-top: rem-calc(50px);
        }
        &.is-open~.overlay-nav-menu-toggle {
            display: inline-block;
            transition: all 0.4s ease-in-out;
            transform: rotate(135deg);
            color: red;
        }
    }
    .sticky,
    .sticky-container,
    .sticky.is-anchored {
        position: initial;
    }
    .sticky {
        transform: initial;
        -webkit-transform: initial;
    }
}

@include breakpoint(large) {
    .logo {
        background: url("../img/colorLogoLarge.png") no-repeat;
        height: 183px;
        width: 330px;
        display: inline-block;
    }
    .top-bar,
    .top-bar ul {
        background: none;
        z-index: 999;
    }
    .top-bar .menu {
        line-height: 183px;
        position: absolute;
        right: 50px;
        top: 0;
    }
    .top-bar .menu a {
        font-weight: bold;
    }
    .button.hollow {
        border: 2px solid $white;
        color: $white !important;
        background: rgba($black, 0.6);
    }
    .orbit {
        margin-top: -206px;
        img {
            width: 100%;
        }
    }
    .orbit-bullets {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .overlay {
        background: rgba($black, 0.6);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .intro,
    .orbit-bullets {
        z-index: 999;
    }
    .intro .button {
        font-weight: bold;
    }
}

@include breakpoint(large) {

    .top-bar.smallNav, .top-bar.shrink {

        background: $black;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        .logo {
            background: url("../img/colorLogo.png") no-repeat;
            height: 73px;
            width: 124px;
        }
        .menu {
            line-height: 73px;

            a {
                color: white;
            }

        }
        .button.hollow {
            border: 2px solid $white;
            color: $white !important;
            background: none;
        }
    }
}

@include breakpoint(large) {
    .intro {
        background: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        h1 {
            font-size: rem-calc(50px);
            color: $white;
        }
    }
}

@include breakpoint(xlarge) {
    .intro {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        h1 {
            font-size: rem-calc(55px);
            color: $white;
        }
    }
}

@include breakpoint(xxlarge) {
    .intro {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        h1 {
            font-size: rem-calc(55px);
            color: $white;
        }
    }
}

@include breakpoint(xxxlarge) {
    .intro {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        h1 {
            font-size: rem-calc(55px);
            color: $white;
        }
    }
}