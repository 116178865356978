.contact {
    margin-top:20px;
    margin-bottom:120px;
    label {
        font-weight: bold;
    }

    textarea {
        height:200px;
    }
}