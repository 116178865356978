
.safteyLeftGraphic {
  // border-bottom:10px dashed red;
  // border-right:10px dashed red;
  text-align: center;
  padding:10px 30px;
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.25rem;
      line-height:1.5rem;
      color: #353333;
    }
    @include breakpoint(medium) {
      img {
        max-width: 380px;
      }
    }
}

.safteyRightGraphic {
  // border-bottom:10px dashed red;
  text-align: center;
  padding:10px 30px;
    h3 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1.25rem;
      line-height:1.5rem;
      color: #353333;
    }
    @include breakpoint(medium) {
      img {
        max-width: 380px;
      }
    }
}

.safetyTipNumber {
  width:50px;
  line-height: 25px;
  color:red;
  float:left;
  span {
    font-size:1.3rem;
    color:black;
  }
}