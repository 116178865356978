.droneClass {
  position: relative;
}

.moreInfo {
  @include breakpoint(medium) { 
    position: absolute;
    bottom: -50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    width:60%;
  }
  margin: 0 auto;
  display: block;
  width: 80%;
}