.boxShaddow {
  -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1);
  -moz-box-shadow: 0 1px 2px rgba(0,0,0,.1);
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  background: #fff;
  border: 1px solid #ededed;
  box-sizing: border-box;
}

ul.videoThumbs {
  display: block;
  list-style-type: none;
  width: 100%;
  margin: 20px auto;
  padding: 0;
  text-align: center;
}

.padding {
  padding: 18px !important;
}

ul.videoThumbs li a {
  width: 100%;
  display: block;
}

ul.videoThumbs li img {
  width: 100%;
  cursor: pointer;
}

ul.videoThumbs li {
  // float: left;
  display: inline-block;
  vertical-align: top;
  width: 49%;
  /* height: 315px; */
  padding: 5px;
  box-sizing: border-box;
  // text-align: left;
}

@media screen and (min-width: 40em) {
    ul.videoThumbs li {
      width: 33%;
  }
}

.infoPane {
  height: 60px;
  margin-top:15px;
}

.arrowControls {
  text-align: center;
  background: #000000;
  position: relative;
}

.arrowControls a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.arrowControls a i {
  position: absolute;
  display: none;
  bottom: 0;
  width: 40px;
  height: 65px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  color:white;
}

.videoOuter {
  background: black;
}

@media screen and (min-width: 40em) {
  .videoOuter {
    margin-top:rem-calc(20px);
  }
}

.videoOuter:hover .arrowControls, .videoOuter.hover .arrowControls {
  background: #2b2a2a;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.videoOuter:hover .arrowControls a i, .videoOuter.hover .arrowControls a i {
  display: block;
}


.arrowControls a:hover i {
  color: #d13631;
  display: block;
}

.playOverlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
}

.playOverlay:hover {
  opacity: 1;
}

.playOverlay img {
  margin-top: 100px;
  width: 96px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.vid {
  position: relative;
  h3 {
    font-size: 1.2rem;
    padding-top:5px;
  }
  p {
    line-height: 18px;
    position: absolute;
    font-size: 14px;
    top: 10px;
    background: white;
    left: 10px;
    padding: 4px;
    font-weight: bold;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 2px rgba(0,0,0,.1);
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    background: #fff;
    border: 1px solid #ededed;
    box-sizing: border-box;
    display: none;
    img {
      width: 30px !important;
      image-rendering: auto;
    }
  }
}

.vid.active p {
  display:block;
}

.vidThumb {
  display: block;
  position: relative;
}

.videoContainer {
  background: url('/assets/img/placeholder.png') no-repeat center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

ul.videoThumbs li.vidSection {
  display: block;
  clear:both;
  width:100%;
  img{
    width:auto;
  }
}
