.blackBG {
    background: $black;
    padding: rem-calc(45px 0 35px 0);
    //margin: rem-calc(25px auto);
    color: $white;
    width:100%;
    p {
        color: $white;
    }
    h2, h3 {
        color:white;
    }
    a {
        color:white;
        text-decoration: underline;
        &.button {
            text-decoration: none;
        }
    }
}

footer.blackBG {
    padding: rem-calc(0 0 5px 0); 
    width:100%;
}
.whiteCenter {
    margin: rem-calc(36px auto 60px auto);
    text-align: center;
}
.whiteBG {
    margin: rem-calc(36px auto 60px auto);
}

.intro {
    text-align: center;
    background-color: #92c5ec;

    h2 {
        font-family: pirulen, sans-serif;
        font-size: 3.5rem;
        font-weight: 200;
        line-height: 1;
        margin-top: 1rem;
    }

    p {
        font-size: 2rem;
        color: #0d0a01;
    }
}

//footer
footer.blackBG {
    margin-bottom: 0px;
    padding-bottom: rem-calc(5px);
}

.orbit-bullets {
    margin-top: -50px;
}

.relative {
    position: relative;
}

p, ul, ol {
    color: #5d5b5b;
}

img.drone-hr {
    margin-bottom: 40px;
}

body {
    height: auto !important;                                                                        
    display: flex;                                                                                  
    min-height: 100vh;                                                                             
    flex-direction: column; 
}                                                                                          
                                                                       
.content {
    flex: 1 0;
} 
h1, h2, h3 {
    color:black;
}                                                                                       

h2 a, h3 a {
    color:black;
}

h2 a:hover, h3 a:hover {
    color:black;
    text-decoration: underline;
}